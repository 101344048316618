import { Controller } from "stimulus"

export default class extends Controller {
  filter(event) {
    // detect the enter key
    if(event.keyCode == 13) {
      Turbolinks.visit(`${location.pathname}?query=${event.target.value.trim()}`)
    }
  }
}
