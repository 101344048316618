import { Controller } from "stimulus"

export default class extends Controller {
  static values = { message: String }

  show() {
    this.tipWrap.innerHTML = this.messageValue
  }

  hide() {
    this.tipWrap.innerHTML = ""
  }

  get tipWrap() {
    return document.querySelector('#tip')
  }
}
